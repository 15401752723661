import { defineStore } from 'pinia';
import { useRuntimeConfig } from '#app';
import { useAuthStore } from '~~/stores/AuthStore';

export const useCoachStore = defineStore({
  id: 'coaches',
  state: () => ({
    removeDateTime: false,
    coach: null,
    coachAppointments: {},
    allCoaches: null,
    mainPageCoaches: null,
    mainPageRates: null,
    serverErrors: {},
    loading: false,
    filters: null,
    pagination: null,
    chips: [],

    coachesFirebase: [],

    customFilter: {},
  }),
  getters: {
    hasCoachRates: (state) => {
      if (state.coach) {
        return 'coachRates' in state.coach;
      }
    },
    hasCoachAppointments: (state) => {
      return 'appointments' in state.coachAppointments;
    },
  },
  actions: {
    async fetch(payload) {
      this.loading = true;
      this.customFilter = {};

      if (payload.filters) {
        Object.entries(payload.filters).forEach(([key, value]) => {
          const arr = value;

          if (key == 'level') {
            this.customFilter[`filter[${key}]`] = value;
          } else if (typeof value === 'object' && value) {
            for (const [index, value] of arr.entries()) {
              this.customFilter[`filter[${key}][${index}]`] = value;
            }
          } else if (typeof value === 'string' && value) {
            this.customFilter[`filter[${key}][]`] = value;
          }
        });
      }

      // To extract pagination data and exculde filters
      // eslint-disable-next-line no-unused-vars
      const { filters, ...paginationFilters } = payload;

      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/coaches`,
        {
          method: 'GET',
          params: {
            ...this.customFilter,
            ...paginationFilters,
          },
        }
      );
      this.loading = false;
      if (!error.value) {
        this.allCoaches = response.value.data.coaches;
        this.pagination = response.value.data;
      } else {
        checkStatusCode(error.value.data.status_code);
        this.serverErrors = error.value.data.errors;
      }
    },

    async fetchMainPageCoaches(payload = {}) {
      this.loading = true;
      const customFilter = {};

      if (payload.filters) {
        for (const key in payload.filters) {
          if (
            payload.filters[key] ||
            (typeof payload.filters[key] === 'string' &&
              payload.filters[key].length > 0)
          ) {
            customFilter[`filter[${key}]`] = payload.filters[key];
          }
        }
      }

      // eslint-disable-next-line no-unused-vars
      const { filters, ...paginationFilters } = payload;

      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/coaches/main-page`,
        {
          method: 'GET',
          params: {
            ...customFilter,
            ...paginationFilters,
          },
        }
      );
      this.loading = false;
      if (!error.value) {
        // this.mainPageCoaches = response.value.data.coaches;
        this.allCoaches = response.value.data.coaches;
        this.pagination = response.value.data;

      } else {
        checkStatusCode(error.value.data.status_code);
        this.serverErrors = error.value.data.errors;
      }
    },
    async fetchMainPageRates() {
      const { checkStatusCode } = useCheckRequestStatusCode();

      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/rates`,
        {
          method: 'GET',
        }
      );
      this.loading = false;
      if (!error.value) {
        this.mainPageRates = response.value.data;
      } else {
        checkStatusCode(error.value.data.status_code);
        this.serverErrors = error.value.data.errors;
      }
    },
    async getCoach(coachId) {
      this.getCoachAppointments(coachId);
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/coaches/${coachId}`,
        {
          method: 'GET',
        }
      );
      this.loading = false;
      if (!error.value) {
        this.coach = response.value.data;
      } else {
        this.serverErrors = error.value.data.errors;
      }
    },

    async getCoachAppointments(id, page = 1) {
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/coaches/${id}/appointments`,
        {
          method: 'GET',
          params: { page },
        }
      );
      this.loading = false;
      if (!error.value) {
        this.coachAppointments = response.value.data;
      } else {
        this.serverErrors = error.value.data.errors;
      }
    },
  },
});
