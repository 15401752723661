export default defineNuxtRouteMiddleware((to) => {
  if (process.client) {
    const { getValue } = useLocalStorage();
    const token = getValue('token')    
    if (token && token.length > 0 && to.path && to.path.includes('auth-') ) {

      to.name = 'index';
      return navigateTo('/');
    }
  }
});
